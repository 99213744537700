<template>
  <div class="isTransparentBg"></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    window.addEventListener("beforeunload", function(e) {
      e.preventDefault();
      e.returnValue = "";
    });
    window.close();
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
